'use client'

import { api } from '@/api'
import { Slider } from '../../atoms/slider/slider'
import { V2ArticleTile } from '../custom-tile/article-tile/article-tile'
import { ComponentProps, useEffect, useMemo, useState } from 'react'
import { useUser } from '@/hooks/use-user'
import { Article } from '@/types/Article'

const fetchTheNewestPlanningArticle = async () => {
  const [ptErr, planningTags] = await api.fe.tags.getMany({
    section: 'Inspiration',
  })

  if (ptErr) return []

  const planningTagId = planningTags.find((tag) => tag.name === 'Planning')?.id

  if (!planningTagId) return []

  const [err, res] = await api.be.article.search({
    type: 'planning',
    sort: 'published_at_desc',
  })

  if (err) return []
  return res?.results || []
}

const fetchVenueArticles = async () => {
  const [err, res] = await api.fe.tags.getMany({
    disabled_onboard: false,
    section: 'Planning',
  })

  if (err) return []
  const venueTagId = res.find((tag) => tag.name === 'Venues')?.id
  if (!venueTagId) return []

  const [articlesErr, articlesRes] = await api.fe.article.search({
    tag: venueTagId,
    sort: 'published_at_desc',
  })

  if (articlesErr) return []
  return articlesRes?.results || []
}

const fetchRecommendedArticles = async ({
  showVenue = false,
}: {
  showVenue?: boolean
}) => {
  return showVenue
    ? await fetchVenueArticles()
    : await fetchTheNewestPlanningArticle()
}

type Props = {
  sliderProps?: ComponentProps<typeof Slider>
}

export const RecommendedArticleSlider = (props: Props) => {
  const { sliderProps } = props
  const { user, isReader } = useUser()
  const venueSelected = useMemo(() => {
    return user?.event?.selected_business_categories.some(
      ({ business_category }) => business_category.name === 'Venues'
    )
  }, [user])

  const [recommendedArticles, setRecommendedArticles] = useState<Article[]>([])

  useEffect(() => {
    if (!isReader) {
      return
    }

    fetchRecommendedArticles({ showVenue: !venueSelected }).then((articles) => {
      setRecommendedArticles(articles)
    })
  }, [isReader])

  if (!recommendedArticles?.length) return null

  return (
    <Slider {...sliderProps}>
      {recommendedArticles?.map((a) => (
        <V2ArticleTile article={a} key={a.id} adjustToSlider />
      ))}
    </Slider>
  )
}
