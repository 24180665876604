'use client'

import { styled, css } from 'styled-components'

export const ContentContainer = styled.div`
  display: block;
  width: 100%;
  padding: 1.5rem;
  min-height: 50px;

  a {
    font-weight: 600;
    text-decoration: underline;
  }

  a.button {
    display: inline-block;
    transition: all 0.2s ease-in-out;
    margin-top: 12px;
    border: 2px solid black;
    padding: 15px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 1.4px;
    text-decoration: none;
    text-transform: uppercase;
  }

  a.button:hover {
    background-color: black;
    color: white;
    text-decoration: underline;
  }
`

export const EmbedContent = styled.div<{ isEmpty?: boolean }>`
  ${({
    isEmpty,
    theme: {
      base: {
        grey,
        shade: { white },
      },
    },
  }) => css`
    ${isEmpty ? `color: ${grey[5]}; font-style: italic;` : ''};
    max-width: 770px;
    width: 100%;
    margin: 2rem auto;
    font-size: 1.125rem;
    .fashpack-button > a {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-color 0.5s ease-out;
      border: 2px solid ${'#000000'};
      padding: 15px;
      width: fit-content;
      font-weight: 600;
      font-size: 0.75rem;
      text-align: center;
      text-decoration: none;
      &:hover {
        transition: background-color 0.5s ease-in;
        background-color: ${'#000000'};
        color: ${white[0]};
      }
    }
    .fashpack-button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  `}
`

export default ContentContainer
