import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/columns.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/content.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/horizonalRule.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/blocks/quote.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/bottomCTA/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmbedIcon","HorizontalDivider","ColumnsIcon","ButtonIcon","SingleImage","ImagesGrid","QuoteIcon","BadgeIcon","LandingPage"] */ "/app/src/page-style/admin/editor-console/content/widget/widget.style.ts");
;
import(/* webpackMode: "eager" */ "/app/src/v2/components/atoms/section-container/section-container.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/variables.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/v2/components/atoms/image/image.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/v2/components/atoms/section-header/section-header.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/v2/components/atoms/slider/slider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tile"] */ "/app/src/v2/components/atoms/tile/tile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecycleCategorySlider"] */ "/app/src/v2/components/molecules/custom-slider/recycle-category-slider/recycle-category-slider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/v2/components/molecules/custom-slider/venues-slider/venues-slider.module.css");
;
import(/* webpackMode: "eager" */ "/app/src/v2/components/molecules/custom-tile/bc-tile/bc-tile.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["V2SupplierSearchSection"] */ "/app/src/v2/components/organisms/supplier-search-section/supplier-search-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["V2HeroSectionLandinPage"] */ "/app/src/v2/components/pages/landing-page/landing-page-hero-section/landing-page-hero-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LandingPageUserArticleCarousel"] */ "/app/src/v2/components/pages/landing-page/landing-page-user-article-carousel/landing-page-user-article-carousel.tsx");
