'use client'

import { ContentContainer, EmbedContent } from './styles/content.style'

export interface ContentBlockType {
  content: string
  html?: string
}

const Content = (props: {
  content: ContentBlockType
  editable?: boolean
  editAvailable?: () => void
}) => {
  const { editAvailable } = props
  const embedValue: any = !props.content ? 'Empty..' : props.content
  const onEditable = () => {
    if (!props.content) {
      if (editAvailable) editAvailable()
    }
  }
  const replaceBaseCCM = !Array.isArray(embedValue)
    ? embedValue.split('{CCM:BASE_URL}').join('')
    : []

  return (
    <ContentContainer>
      <EmbedContent
        onClick={onEditable}
        isEmpty={!props.content}
        dangerouslySetInnerHTML={{ __html: replaceBaseCCM }}
      />
    </ContentContainer>
  )
}

export default Content
