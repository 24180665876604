import { useWindowSize } from 'usehooks-ts'

export type DeviceType = 'mobile' | 'tablet' | 'desktop'
export const useDevice = () => {
  const { width } = useWindowSize()

  if (width < 768) {
    return 'mobile'
  }

  if (width < 1024) {
    return 'tablet'
  }

  return 'desktop'
}
