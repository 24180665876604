'use client'

import styled from 'styled-components'

export const HR = styled.hr`
  ${({
    theme: {
      base: { grey },
    },
  }) => `
    box-sizing: content-box;
    height: 1px;
    overflow: visible;
    border-top: 2px solid ${grey[5]};
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin: 2rem 0;
  `}
`

export default HR
