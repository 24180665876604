import cx from 'classnames'
import { ReactElasticCarouselProps } from 'react-elastic-carousel'
import { Image } from '@/v2/components/atoms/image/image'
import Link from 'next/link'
import s from './cta-carousel.module.css'
import { Slider } from '@/v2/components/atoms/slider/slider'
import { CSS_BREAKPOINTS, SLIDER_CLASS_NAME } from '@/constants'

export type CTACarouselItem = {
  imageUrl: string
  imageAlt: string
  redirectUrl?: string | String
  title: string
  content?: string
  size?: number
}

type CTACarouselProps = {
  items: CTACarouselItem[]
  breakPoints?: Pick<ReactElasticCarouselProps, 'breakPoints'>
  className?: string
}

const ActionComponent = ({
  item,
  className,
}: {
  item: CTACarouselItem
  className?: string
}) => {
  return (
    <div
      className={cx(s.itemContainer, className)}
      style={{
        cursor: item.redirectUrl ? 'pointer' : 'default',
      }}
    >
      <div className={s.itemImageWrapper}>
        <Image
          width={item.size || 150}
          height={item.size || 150}
          src={item.imageUrl}
          alt={item.imageAlt}
        />
      </div>
      <h5 className={s.itemTitle}>{item.title}</h5>
      {item.content && <p className={s.itemContent}>{item.content}</p>}
    </div>
  )
}

const carouselBreakpoints = {
  small: `(max-width: ${CSS_BREAKPOINTS.small})`,
  medium: `(max-width: ${CSS_BREAKPOINTS.medium})`,
  large: `(max-width: ${CSS_BREAKPOINTS.large})`,
  xlarge: `(max-width: ${CSS_BREAKPOINTS.xlarge})`,
}

const CTACarousel: React.FC<CTACarouselProps> = ({ items, className }) => {
  return (
    <div className={cx(className, s.carouselContainer)}>
      <Slider
        options={{
          slides: {
            perView: 5,
            spacing: 24,
          },
          breakpoints: {
            [carouselBreakpoints.large]: {
              slides: {
                perView: 3.7,
                spacing: 24,
              },
            },
            [carouselBreakpoints.medium]: {
              slides: {
                perView: 2.7,
                spacing: 24,
              },
            },
            [carouselBreakpoints.small]: {
              slides: {
                perView: 1.7,
                spacing: 24,
              },
            },
          },
        }}
      >
        {items.map((item, i) => {
          return item.redirectUrl ? (
            <Link
              style={{ display: 'block' }}
              className={SLIDER_CLASS_NAME}
              href={item.redirectUrl.toString()}
              key={`${item.title}+${i}`}
            >
              <ActionComponent item={item} />
            </Link>
          ) : (
            <ActionComponent
              key={`${item.title}+${i}`}
              className={SLIDER_CLASS_NAME}
              item={item}
            />
          )
        })}
      </Slider>
    </div>
  )
}

export default CTACarousel
