'use client'

import UpperCTA from '@/components/landingPage/upperCTA'
import s from './landing-page-user-article-carousel.module.css'
import { useUser } from '@/hooks/use-user'
import { SectionContainer } from '@/v2/components/atoms/section-container/section-container'
import { Slider } from '@/v2/components/atoms/slider/slider'
import { Button } from '@/v2/components/atoms/button/button'
import { Article } from '@/types/Article'
import { SectionHeader } from '@/v2/components/atoms/section-header/section-header'
import HeroCreateAccount from '@/components/landingPage/heroCreateAccount'
import { ROUTES } from '@/constants'
import { V2ArticleTile } from '../../../molecules/custom-tile/article-tile/article-tile'
import { RecommendedArticleSlider } from '@/v2/components/molecules/recommended-article-slider/recommended-article-slider'
import { useDevice } from '@/hooks/use-device'

type Props = {
  mostRecentInspirationArticles: Article[]
  weddingPlanningArticles: Article[]
}

export const LandingPageUserArticleCarousel = (props: Props) => {
  const { mostRecentInspirationArticles, weddingPlanningArticles } = props
  const { isReader } = useUser()
  const device = useDevice()

  if (isReader) {
    return (
      <>
        <SectionContainer sliderSection style={{ marginTop: '16px' }}>
          <SectionHeader>RECOMMENDED ARTICLES FOR YOU</SectionHeader>
          <RecommendedArticleSlider />
        </SectionContainer>
        <SectionContainer>
          <div className={s.center}>
            <Button href={ROUTES.WEDDING_INSPIRATION}>
              EXPLORE ALL ARTICLES
            </Button>
          </div>
        </SectionContainer>
      </>
    )
  }

  return (
    <>
      <SectionContainer>
        <UpperCTA />
      </SectionContainer>
      <SectionContainer sliderSection style={{ marginTop: '16px' }}>
        <SectionHeader>Wedding planning</SectionHeader>
        <Slider>
          {weddingPlanningArticles?.map((a) => (
            <V2ArticleTile article={a} key={a.id} adjustToSlider />
          ))}
        </Slider>
      </SectionContainer>
      <SectionContainer sliderSection style={{ marginTop: '16px' }}>
        <SectionHeader>
          {device === 'mobile'
            ? 'WEDDING IDEAS'
            : 'WEDDING IDEAS & INSPIRATION'}
        </SectionHeader>
        <Slider>
          {mostRecentInspirationArticles?.map((a) => (
            <V2ArticleTile article={a} key={a.id} adjustToSlider />
          ))}
        </Slider>
      </SectionContainer>
      <SectionContainer>
        <div className={s.center}>
          <Button href={`/wedding-inspiration`}>get inspired</Button>
        </div>
      </SectionContainer>
      <SectionContainer>
        <HeroCreateAccount />
      </SectionContainer>
    </>
  )
}
