'use client'

import { PROD_URL, ROUTES } from '../../../constants'
import CTACarousel, { CTACarouselItem } from '../../ui/ctaCarousel'

const config: CTACarouselItem[] = [
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/inspiration.png`,
    imageAlt: 'be inspired',
    redirectUrl: ROUTES.WEDDING_INSPIRATION,
    title: 'WEDDING IDEAS',
    content:
      'Get inspired with our real weddings and ideas from real couples.',
    size: 32,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/planning.png`,
    imageAlt: 'start planning',
    redirectUrl: ROUTES.WEDDING_PLANNING,
    title: 'WEDDING PLANNING',
    content: 'Get free planning tools and customisable checklists.',
    size: 32,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/venues.png`,
    imageAlt: 'find venues',
    redirectUrl: ROUTES.VENUE.ROOT,
    title: 'Find venues',
    content: 'Use our finder tool to discover your dream wedding venue.',
    size: 32,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/search-suppliers.png`,
    imageAlt: 'search suppliers',
    redirectUrl: ROUTES.WEDDING_SUPPLIERS.ROOT,
    title: 'Search suppliers',
    content: 'Search thousands of wedding suppliers to assemble your team.',
    size: 32,
  },
  {
    imageUrl: `${PROD_URL}/assets/images/landing-page/pre-loved.png`,
    imageAlt: 'buy pre-loved',
    redirectUrl: ROUTES.RECYCLE.HOME,
    title: 'Buy pre-loved',
    content: 'Buy and sell pre-loved wedding decor, dresses and accessories.',
    size: 32,
  },
]

const UpperCTA: React.FC = () => {
  return <CTACarousel items={config} />
}

export default UpperCTA
