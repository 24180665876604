'use client'

import styled, { css } from 'styled-components'

export const GridWidget = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100px;
  gap: 10px;
  padding: 1rem;

  @container article-content (max-width: 490px) {
    display: block;
  }

  @container article-content (max-width: 590px) {
    display: block;
  }

  @media (max-width: 1140px) {
    flex-wrap: wrap;
  }
`

export const Grid = styled.div`
  display: grid;
  min-height: 100px;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 1rem;

  @container article-content (max-width: 490px) {
    display: block;
  }

  @container article-content (max-width: 590px) {
    display: block;
  }
`

export const GridItem = styled.div<{
  isEmpty?: boolean
  centered?: boolean
}>`
  ${({
    theme: {
      base: { grey },
    },
    isEmpty,
    centered,
  }) => css`
    ${isEmpty ? `color: ${grey[5]}; font-style: italic;` : ''};

    ${centered ? 'text-align: center;' : ''};

    h3 {
      min-height: 100px;
      font-weight: 600;
      text-transform: uppercase;
    }

    h3 > img {
      width: 350px !important;
      height: 500px !important;
      object-fit: contain;
      object-position: center;
    }

    @media (max-width: 590px) {
      h3 > img {
        width: 100% !important;
      }
    }

    p {
      color: #333;
      font-weight: 300;
      font-size: 18px;
      line-height: 1.5;
      font-family: Oxygen, sans-serif;
    }

    a {
      font-weight: 600;
      text-decoration: underline;
    }

    a.button {
      display: inline-block;
      transition: all 0.2s ease-in-out;
      margin-top: 12px;
      border: 2px solid black;
      padding: 15px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1.4px;
      text-decoration: none;
      text-transform: uppercase;
    }

    a.button:hover {
      background-color: black;
      color: white;
      text-decoration: underline;
    }

    p > img {
      width: 350px !important;
      height: 500px !important;
      object-fit: contain;
      object-position: center;
    }

    @media (max-width: 590px) {
      p > img {
        width: 100% !important;
      }
    }
  `}
`

export const ColumnWrapper = styled.div`
  ${({
    theme: {
      base: {
        shade: { white },
      },
    },
  }) => `
    width: 100%;
    margin: 0.1rem;
    flex: none;
    padding: 1rem;
    background-color: ${white[0]};
    width: 100%
    position: relative;

    @container article-content (width: 490px) {
      width: 100%;
    }

    @container article-content (max-width: 590px) {
      width: 100%;
    }    
  `}
`

export const ColumnWidgetWrapper = styled.div`
  ${({
    theme: {
      base: {
        shade: { white },
      },
    },
  }) => `
    margin: 0.1rem;
    flex: none;
    padding: 1rem;
    background-color: ${white[0]};
    width: 400px;
    position: relative;

    @container article-content (width: 490px) {
      width: 100%;
    }

    @container article-content (max-width: 590px) {
      width: 100%;
    }    
  `}
`

export const Title = styled.div`
  font-weight: 700;
  margin: 0 0 1rem;
  font-size: 1.1rem;
  min-height: 100px;

  .heading--5 {
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    object-fit: cover;
  }
`

export default Grid
