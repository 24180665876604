'use client'

import styled, { css } from 'styled-components'

export const Blockquote = styled.blockquote`
  padding: 1.5rem;
`

export const QuotesImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;
`

export const QuoteBody = styled.div<{ isEmpty?: boolean }>`
  ${({
    theme: {
      base: { grey },
    },
    isEmpty,
  }) => css`
    text-align: center;
    margin-bottom: 40px;
    font-size: 1.125rem;
    ${isEmpty ? `color: ${grey[5]}; font-style: italic;` : ''};
    a {
      font-weight: 600;
      text-decoration: underline;
    }
  `}
`

export const QuoteBodyWithBackground = styled(QuoteBody)`
  ${({
    theme: {
      base: {
        shade: { pink },
      },
    },
  }) => `
    background-color: ${pink[7]};
    padding: 1.5rem;
    margin-bottom: 0;
  `}
`

export const QuoteContent = styled.div`
  ${() => css`
    padding: 1.5rem;
    margin-bottom: 0;
    white-space: break-spaces;
    a {
      font-weight: 600;
      text-decoration: underline;
    }
  `}
`

export const QuoteTitle = styled.h2<{ isTitle?: boolean }>`
  ${({
    theme: {
      typography: { font },
    },
    isTitle,
  }) => `
    color: ${'#000000'};
    ${
      isTitle
        ? `
      text-align: center;
      font-family: ${font.DMSerifDisplay},sans-serif;
      font-size: 2.4rem;
    `
        : `font-size: 1.5rem;
    text-align: left;
    text-transform: uppercase;`
    }
    font-weight: 600;
    transition: font-size 0.2s ease-in-out;

    @container article-content (max-width: 780px) {
      font-size: 1.3rem;
    }

    @container article-content (max-width: 390px) {
      font-size: 1rem;
    }
  `}
`

export const QuoteAuthor = styled.p<{ isDefault?: boolean }>`
  ${({
    theme: {
      typography: { font },
    },
    isDefault = true,
  }) => `
    text-align: ${isDefault ? 'center' : 'right; margin: 0 1.7rem'};
    color: ${'#000000'};
    font-family: ${font.oxygen},sans-serif;
    font-size: 1.125rem;
    font-weight: 700;
  `}
`

export default QuoteBody
